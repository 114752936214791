<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Report</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Download Report
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="container">
      <form class="mt-4" @submit.prevent="postReport">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="start_date" class="text-left">Start Date</label>
              <input
                type="date"
                class="form-control b-radius px-4"
                id="start_date"
                aria-describedby="start_dateHelp"
                placeholder="Enter start_date"
                v-model="form.start_date"
                required
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="end_date" class="text-left">End Date</label>
              <input
                type="date"
                class="form-control form-control b-radius px-4"
                id="end_date"
                placeholder="end_date"
                v-model="form.end_date"
                required
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <button type="submit" class="btn btn-primary px-5">Generate</button>
        </div>
      </form>
    </div>
    <div class="row" v-if="showPreview">
      <div class="col-12 col-md-6">
        <h5 class="f-bold pt-2 pl-3 cl-primary text-left">Preview Report</h5>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li
              class="breadcrumb-item active"
              aria-current="page"
              style="cursor: pointer"
              v-on:click.prevent="postRepostAll()"
            >
              Download Report
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="card card-shadow" v-if="showPreview">
      <div class="card-body">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="data-customer-tab"
              data-toggle="pill"
              href="#data-customer"
              role="tab"
              aria-controls="data-customer"
              aria-selected="true"
              >Data Customer</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="customer-rank-tab"
              data-toggle="pill"
              href="#customer-rank"
              role="tab"
              aria-controls="customer-rank"
              aria-selected="false"
              >Customer Ranking</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="invoice-tab"
              data-toggle="pill"
              href="#invoice"
              role="tab"
              aria-controls="invoice"
              aria-selected="false"
              >Invoice</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="packages-tab-tab"
              data-toggle="pill"
              href="#packages-tab"
              role="tab"
              aria-controls="packages-tab"
              aria-selected="false"
              >Packages</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="data-customer"
            role="tabpanel"
            aria-labelledby="data-customer-tab"
          >
            <table class="table table-bordered table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="data.length == 0">
                  <td colspan="7">Customer is Empty</td>
                </tr>
                <tr v-for="(banner, p) in data.customer_list" :key="p">
                  <th scope="row">{{ p + 1 }}</th>
                  <td>{{ banner.name }}</td>
                  <td>{{ banner.email }}</td>
                  <td>{{ banner.telephone }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="customer-rank"
            role="tabpanel"
            aria-labelledby="customer-rank-tab"
          >
            <table class="table table-bordered table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Nama</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Packages Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="data.customer_ranking == 0">
                  <td colspan="7">Data is Empty</td>
                </tr>
                <tr v-for="(banner, p) in data.customer_ranking" :key="p">
                  <th scope="row">{{ p + 1 }}</th>
                  <td>{{ banner.name }}</td>
                  <td>{{ banner.email }}</td>
                  <td>{{ banner.telephone }}</td>
                  <td>{{ banner.customer_package_count }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="invoice"
            role="tabpanel"
            aria-labelledby="invoice-tab"
          >
            <table class="table table-bordered table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Package Code</th>
                  <th scope="col">Carton</th>
                  <th scope="col">CBM</th>
                  <th scope="col">Price Per CBM</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="data.customer_ranking == 0">
                  <td colspan="7">Data is Empty</td>
                </tr>
                <tr v-for="(banner, p) in data.invoice" :key="p">
                  <th scope="row">{{ p + 1 }}</th>
                  <td>{{ banner.package_invoice.code }}</td>
                  <td>{{ banner.carton }}</td>
                  <td>{{ banner.cbm }}</td>
                  <td>{{ banner.price_per_cbm }}</td>
                  <td>{{ banner.total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tab-pane fade"
            id="packages-tab"
            role="tabpanel"
            aria-labelledby="packages-tab"
          >
            <table class="table table-bordered table-hover">
              <thead class="thead-light">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Package Code</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Shipping Address</th>
                  <!-- <th scope="col">Promotion Code</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-if="data.package == 0">
                  <td colspan="7">Data is Empty</td>
                </tr>
                <tr v-for="(banner, p) in data.package" :key="p">
                  <th scope="row">{{ p + 1 }}</th>
                  <td>{{ banner.code }}</td>
                  <td>{{ banner.customer_detail.name }}</td>
                  <td>{{ banner.package_detail.description }}</td>
                  <td>{{ banner.package_detail.shipping_address }}</td>
                  <!-- <td>{{ banner.promotion_detail.code }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "Report",
  data() {
    return {
      loading: false,
      data: "",
      showPreview: false,
      form: {
        start_date: "",
        end_date: "",
      },
    };
  },
  methods: {
    postReport: function () {
      console.log("report" + this.form.type);

      if (this.form.type == "") {
        Vue.swal("Error", "Please choose type report", "error");
      } else {
        Vue.swal({
          title: "Are you sure?",
          text: "The report will generated.",
          icon: "question",
          showCancelButton: true,
          cancelButtonColor: "#eb4d4b",
          confirmButtonText: "Yes, Generate!",
        }).then((result) => {
          if (result.isConfirmed) {
            // this.postRepostAll();
            this.reportPreview(this.form);
          }
        });
      }
    },

    reportPreview(data) {
      this.loading = true;
      const endpoint = "report-all/preview";
      this.$api.post(endpoint, data, (status, data, message) => {
        if (status === 200) {
          this.showPreview = true;
          this.loading = false;
          this.data = data;
          console.log(data);
        } else {
          this.loading = false;
          this.showPreview = false;
          console.log(message);
        }
      });
    },

    postReportCustomerPackage: function () {
      console.log("postReportCustomerPackage");
      var win = window.open(
        "https://api-dev.mimologistics.id/customer-package/export?start_date=" +
          this.form.start_date +
          "&end_date=" +
          this.form.end_date +
          "&Authorization=" +
          localStorage.getItem("token"),
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      }
      // this.loading = true;
      // const endpoint = "/customer-package/export";
      // this.$api.post(endpoint, this.form, (status, data, message) => {
      //   if (status === 200) {
      //     this.loading = false;
      //     console.log(data);
      //     Vue.swal("Success", "Report Generated", "success").then(() => {
      //       // this.$router.push("/customers/my-profile");
      //     });
      //   } else {
      //     this.loading = false;
      //     console.log(message);
      //   }
      // });
    },

    postReportCustomerList: function () {
      console.log("postReportCustomerList");
      this.loading = true;
      // const endpoint = "/customer-list/export";
      var win = window.open(
        "https://api-dev.mimologistics.id/customer-list/export?start_date=" +
          this.form.start_date +
          "&end_date=" +
          this.form.end_date +
          "&Authorization=" +
          localStorage.getItem("token"),
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      }
    },

    postReportCustomerRangking: function () {
      console.log("postReportCustomerRangking");
      this.loading = true;
      // const endpoint = "/customer-ranking/export";
      var win = window.open(
        "https://api-dev.mimologistics.id/customer-ranking/export?start_date=" +
          this.form.start_date +
          "&end_date=" +
          this.form.end_date +
          "&Authorization=" +
          localStorage.getItem("token"),
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      }
    },

    postReportCustomerInvoice: function () {
      console.log("postReportCustomerInvoice");
      this.loading = true;
      // const endpoint = "/invoice/export";
      var win = window.open(
        "https://api-dev.mimologistics.id/invoice/export?start_date=" +
          this.form.start_date +
          "&end_date=" +
          this.form.end_date +
          "&Authorization=" +
          localStorage.getItem("token"),
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      }
    },
    postRepostAll: function () {
      console.log("postReportAll");
      this.loading = true;
      // const endpoint = "/invoice/export";
      var win = window.open(
        "https://api-dev.mimologistics.id/report-all/export?start_date=" +
          this.form.start_date +
          "&end_date=" +
          this.form.end_date +
          "&Authorization=" +
          localStorage.getItem("token"),
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      }
    },
  },
};
</script>
